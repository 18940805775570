<template>
    <div></div>
</template>
<script>
import {getUrlKey} from '@/utils/common.js'
export default {
    name:'backstage',
    data(){
        return{
            monitorLoggedIn:''
        }
    },
    created(){
        console.log(this.$cookies.get('monitorLoggedIn'));
        return;
        let loggedIn=JSON.parse(getUrlKey('obj'));
        console.log(loggedIn);
         console.log(JSON.parse(loggedIn));
        //return;
        localStorage.removeItem("loggedIn", null);
        localStorage.setItem("loggedIn", loggedIn);
        this.$store.commit("setLoggedIn", JSON.parse(loggedIn));
        this.$router.push("/main/home");
    }
}
</script>